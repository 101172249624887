
import { $t } from 'o365-utils';

// These objects are only used in the custom from builder (scope-processes)

// OIFrame from o365-ui-components
export const Scope_ExampleIframe: ElementTypeConfiguration = {
    type: 'Scope_ExampleIframe',
    options: [
        {
            name: 'src',
            caption: $t('URL'),
            allowConditionals: true,
            type: { type: 'string' },
        },
        {
            name: 'enabled',
            caption: $t('Enabled'),
            type: { type: 'boolean' },
            allowConditionals: true
        },
        {
            name: 'class',
            caption: $t('Class'),
            group: $t('UI'),
            type: { type: 'string' }
        }
    ]
};

// OActionSave from o365-data-components
export const Scope_ExampleSaveButton: ElementTypeConfiguration = {
    type: 'Scope_ExampleSaveButton',
    options: [
        {
            name: 'dataObject',
            caption: $t('Data Object'),
            type: { type: 'dataObject' }
        },
        {
            name: 'hideSpinner',
            caption: $t('Hide Spinner'),
            type: { type: 'boolean' }
        }
    ]
};

// OActionSave from o365-data-components
export const Scope_Sharing: ElementTypeConfiguration = {
    type: 'Scope_Sharing',
    options: [
        {
            name: 'dataObject',
            caption: $t('Data Object'),
            type: { type: 'dataObject' }
        },
        {
            name: 'class',
            caption: $t('Class'),
            group: $t('UI'),
            type: { type: 'string' }
        }
    ]
};

// OActionSave from o365-data-components
export const Scope_Related: ElementTypeConfiguration = {
    type: 'Scope_Related',
    options: [
        {
            name: 'dataObject',
            caption: $t('Data Object'),
            type: { type: 'dataObject' }
        },
        {
            name: 'class',
            caption: $t('Class'),
            group: $t('UI'),
            type: { type: 'string' }
        }
    ]
};

// OActionSave from o365-data-components
export const Scope_Attachments: ElementTypeConfiguration = {
    type: 'Scope_Attachments',
    options: [
        {
            name: 'dataObject',
            caption: $t('Data Object'),
            type: { type: 'dataObject' }
        },
        {
            name: 'class',
            caption: $t('Class'),
            group: $t('UI'),
            type: { type: 'string' }
        }
        
    ]
};

// OActionSave from o365-data-components
export const File_Upload: ElementTypeConfiguration = {
    type: 'Scope_FileUploadBtn',
    options: [
        
        {
            name: 'class',
            caption: $t('Class'),
            group: $t('UI'),
            type: { type: 'string' }
        }
    ]
};

// #region Helper types
// Manualy copied from the lib since sitesetup doesnt support type acquisition yet
export type ConfigurationProperty = {
    /** Name of the property */
    name: string;
    /** UI friendly caption for the property (shown in the designer) */
    caption?: string;
    /** The value type of this prop  */
    type: ConfigurationPropertyType;
    /** When `true` will allow the value to be conditional */
    allowConditionals?: boolean;
    /** Group this prop with others that have the same group name (not used in the runtime form) */
    group?: string;
    /** Sort weight for this prop in the editor (not used in the runtime form) */
    sort?: number;
}

export type ConfigurationPropertyType = {
    type: 'object';
    properties: Record<string, ConfigurationPropertyType>
    required?: boolean;
} | {
    type: 'array';
    items: ConfigurationPropertyType | ConfigurationPropertyType[]
    required?: boolean;
} | {
    type: 'translatedString',
} | {
    type: 'string';
    required?: boolean;
    defaultValue?: string;
    enum?: string[],
} | {
    type: 'number';
    required?: boolean;
    defaultValue?: number;
    enum?: number[],
} | {
    type: 'boolean'
    required?: boolean;
    defaultValue?: boolean;
} | {
    type: | 'date' | 'datetime'
    required?: boolean;
    defaultValue?: string;
} | {
    type: 'lookup',
    displayMember: string,
    valueMember: string,
    viewName: string,
    sortOrder?: string,
    columns?: string,
    whereClause?: string,
} | {
    type: 'dataObject'
} | {
    type: 'system_label' // Non editable value (shown in the editor)
    value: string
} | {
    type: 'system_elementField'; // System type, a field from the element  
    name: string;
}

export type ElementTypeConfiguration = {
    type: string;
    options: ConfigurationProperty[];
    /** Settings for base options  */
    baseOptions?: {
        /**
         * Disable configuring 'v-if' for this element type 
         * @default false
         */
        disableConditionalRendering?: boolean;
    };
    /** UI facing string of this type (used in the editor). Supports templating like "Field: {name}" */
    caption?: string;
    /** Group under which this element type is placed when a list of all available components is shown */
    group?: string;
}


// #endregion